/* eslint-disable no-restricted-globals */
/* eslint-disable dot-notation */
/* eslint-disable no-param-reassign */
/* eslint-disable arrow-parens */
import axios from 'axios';
import { Message, MessageBox } from 'element-ui';
import { getToken } from '@/utils/auth';
import store from '../store';

const service = axios.create({
  baseURL: 'https://swingby-api.oceanorth.com/api/v1',
  timeout: 15000,
});

service.interceptors.request.use(config => {
  if (store.getters.token) {
    config.headers['Authorization'] = getToken();
  }
  config.headers['Accept'] = 'application/json';
  return config;
}, error => {
  Promise.reject(error);
});

service.interceptors.response.use(
  response => response,
  error => {
    Message({
      message: error.message,
      type: 'error',
      duration: 3 * 1000,
    });
    return Promise.reject(error);
  },
);

export default service;
