<template>
  <div class="app-container">
    <el-card class="form-container" shadow="never">
      <el-form ref="loginForm" :model="loginParam" label-width="150px">
        <el-form-item label="Email" prop="email">
          <el-input
            v-model="loginParam.email"
            :rules="[
              {
                required: true,
                message: 'Field can\'t be empty',
                trigger: 'blur',
              },
            ]"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="getToken()">创建</el-button>
        </el-form-item>
        <!-- <el-form-item label="验证码">
                    <el-input v-model="token" auto-complete="off"></el-input>
                </el-form-item> -->
        <el-form-item>
          <el-button @click="handleReturn()" type="primary">返回列表</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import { getToken } from '@/api/consumer/login';

export default {
  name: 'ConsumerLogin',
  data() {
    return {
      loginParam: {
        email: null,
        isAdmin: true,
      },
      token: null,
    };
  },
  methods: {
    getToken() {
      getToken(this.loginParam).then((response) => {
        this.token = response.data;
        this.$message({
          message: 'Created',
          type: 'success',
          duration: 1000,
        });
      });
    },
    handleReturn() {
      this.$router.push({ path: '/adminConsumer/adminConsumer' });
    },
  },
};
</script>
